<template>
    <div class="FileEdit">
        <Modal v-if="docinfo !=null" v-model="showpreview" title="文档内容预览" width="90%" margin-top:10px @on-ok="showpreview=false">
            <div id="previewdiv"  style="margin:0 auto;float:top;margin-top:1px;padding: 5px;width:1000px;background:rgb(233, 238, 234)">
                <div v-html="docinfo.content" ></div>
            </div>
        </Modal>
      <h1>编辑文档</h1>
      <div id="editdiv" v-if="docinfo !=null">
          <h3>文档标题：</h3>
          <Input v-model="docinfo.title" placeholder="文档标题" style="width: 100%;"></Input>

          <h3>文档内容：</h3>
        
          <tinymce ref="editor" v-model="docinfo.content" style="margin-top: 20px;height:400px"/>
          </div>
          <div id="btnbar">
              <Button @click="closewindow" type="error" ghost>关闭窗口</Button>
              <Button @click="clear" type="success" ghost >清空内容</Button>
              <Button @click="preview" type="warning"  >预览</Button>
              <Button @click="submmit" type="primary">提交</Button>
          </div>
      </div>
    </div>

  </template>
  
  <script>
  import tinymce from '@/components/tinymce.vue'
  export default {
    name: "FileContentEdit",
    props: {
    },
    data(){
        return {
            docid:window.atob(this.$route.query.docid),
            userlevel:this._self.$root.$store.state.LogonInfo.level,
            companyid:this._self.$root.$store.state.LogonInfo.companyid,
            userCategory:this._self.$root.$store.state.LogonInfo.userCategory,
            docinfo:null,
            showpreview:false
        }
    },
    components:{
      tinymce
    },
    mounted(){
      //this.refresh();
      this.querydoc()
    },
    methods:{
        closewindow(){
            window.close()
        },
        // 清空内容
        clear () {
            this.$refs.editor.clear()
        },
        onEditorChange(event) {
            console.log('onEditorChange')
        },
        querydoc(){
            let posturl = "/api/checkdept/ycwj/get"

            let postdata={
                docid: this.docid,
                companyid:this.companyid
            }

            this.$Loading.start();
            this.$axios({
                method:'post',
                url:posturl,
                data:postdata,
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
                }).then(function(res){
                if(res.data.resultCode ==0){
                    this.$Loading.finish();

                    this.docinfo = res.data.data
                    console.log(this.docinfo)
                }
                else{
                    this.$Loading.error();
                    this.$Message.error({content:res.data.msg, duration:3})
                }
                }.bind(this));   
        },
        preview(){
            this.showpreview = true
        },
        submmit(){
            console.log(this.docinfo)
            let posturl = "/api/checkdept/ycwj/update"
            this.$axios({
                method:'post',
                url:posturl,
                data:this.docinfo,
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3, closable:true})
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this));     
        }
    }
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style >
      .FileEdit {
        margin: 0 auto;
        padding: 5%;
        width:1100px;
      }
    #editdiv {
      margin:0 auto;
      text-align: left
    }
  
    #editdiv h3{
      margin: 15px auto;
    }
  
    #btnbar{
      margin: 20px auto;
      text-align: center;
    }
  
    #btnbar button{
        width:150px;
        margin:10px;
    }
    #editdiv .ql-editor{
      height:400px;
    }
  
  </style>  